import teamClient from '@/clients/teamClient'
import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'
import { TeamPlayerInfo } from '@/GeneratedTypes/ListInfo/TeamPlayerInfo'
import { toDivisionTeam } from '@/common/typeConverstions/team'
import { defineGetters, defineActions, defineMutations } from 'direct-vuex'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'
import store from '@/store'

interface TeamState {
  teams: DivisionTeamInfo[]
  currentTeam: DivisionTeamInfoExt | null
  teamsForCurrentUser: DivisionTeamInfoExt[]
}

const initState = (): TeamState => {
  return {
    teams: [] as DivisionTeamInfo[],
    currentTeam: null,
    teamsForCurrentUser: [] as DivisionTeamInfoExt[],
  }
}

const programState = initState()

export enum getterNames {
  teams = 'teams',
  currentTeam = 'currentTeam',
  currentTeamNumberOfSegments = 'currentTeamNumberOfSegments',
  teamsForCurrentUser = 'teamsForCurrentUser',
  currentTeamPlayers = 'currentTeamPlayers',
  hasScheduledGames = 'hasScheduledGames',
  hasScheduledAppointments = 'hasScheduledAppointments',
}

const getterTree = defineGetters<TeamState>()({
  [getterNames.teams]: (state) => state.teams,
  [getterNames.currentTeam]: (state) => state.currentTeam,
  [getterNames.currentTeamNumberOfSegments]: (state) => {
    if (state.currentTeam) {
      if (
        state.currentTeam.overrideNumberOfSegments != null &&
        state.currentTeam.overrideNumberOfSegments > 0
      ) {
        return state.currentTeam.overrideNumberOfSegments
      }
      const typeID: UpwardProgramTypeID = store.getters.upwardTypes.programTypes.find(
        (x: UpwardProgramTypeID) => x.upwardTypeID == state.currentTeam!.typeProgramID
      )
      return typeID?.numberOfSegments ?? 0
    } else {
      return 0 //don't think we should ever get here
    }
  },
  [getterNames.teamsForCurrentUser]: (state) => state.teamsForCurrentUser,
  [getterNames.currentTeamPlayers]: (state) => state.currentTeam?.players,
  [getterNames.hasScheduledGames]: (state) => {
    if (!state.currentTeam?.games) return false
    return state.currentTeam?.games.length > 0
  },
  [getterNames.hasScheduledAppointments]: (state) => {
    if (!state.currentTeam?.appointments) return false
    return state.currentTeam?.appointments.length > 0
  },
})

export enum mutationNames {
  setTeams = 'setTeams',
  setCurrentTeam = 'setCurrentTeam',
  setCurrentTeamPlayers = 'setCurrentTeamPlayers',
  setTeamsForCurrentUser = 'setTeamsForCurrentUser',
  reset = 'reset',
}

const mutations = defineMutations<TeamState>()({
  [mutationNames.setTeams](state, { teams }: { teams: DivisionTeamInfo[] }) {
    state.teams = teams
  },
  [mutationNames.setCurrentTeam](state, { team }: { team: DivisionTeamInfoExt | null }) {
    state.currentTeam = team
  },
  [mutationNames.setTeamsForCurrentUser](state, { teams }: { teams: DivisionTeamInfoExt[] }) {
    state.teamsForCurrentUser = teams
  },
  [mutationNames.setCurrentTeamPlayers](state, { players }: { players: TeamPlayerInfo[] }) {
    if (!state.currentTeam) return
    state.currentTeam.players = players
  },
  [mutationNames.reset](state) {
    Object.assign(state, initState())
  },
})

export enum actionNames {
  fetchAndSetCurrentTeam = 'fetchAndSetCurrentTeam',
  saveCurrentTeam = 'saveCurrentTeam',
  fetchTeamsByProgram = 'fetchTeamsByProgram',
  fetchLeagueTeams = 'fetchLeagueTeams',
  fetchTeamsForACoach = 'fetchTeamsForACoach',
  saveRosterChanges = 'saveRosterChanges',
}

const actions = defineActions({
  async [actionNames.fetchTeamsByProgram](
    { commit, getters },
    {
      upwId,
      programTypeId,
      force = false,
    }: { upwId: string | null; programTypeId: string | null; force: boolean }
  ): Promise<boolean> {
    if (getters[getterNames.teamsForCurrentUser].length && !force) return false
    const result = await teamClient.retrieveTeamList({ upwId, programTypeId })

    if (result) {
      commit(mutationNames.setTeamsForCurrentUser, { teams: result })
      return true
    }

    return false
  },

  async [actionNames.fetchLeagueTeams](
    { commit, getters },
    { upwId, force = false }: { upwId: string | null; programTypeId: string | null; force: boolean }
  ): Promise<boolean> {
    if (getters[getterNames.teamsForCurrentUser].length && !force) return false
    const result = await teamClient.retrieveLeagueTeamList({ upwId })

    if (result) {
      commit(mutationNames.setTeamsForCurrentUser, { teams: result })
      return true
    }

    return false
  },

  async [actionNames.fetchTeamsForACoach](
    { commit, getters },
    { force = false }: { force: boolean }
  ): Promise<boolean> {
    if (getters[getterNames.teamsForCurrentUser].length && !force) return false

    const result = await teamClient.retrieveCoachTeamList()

    if (result) {
      commit(mutationNames.setTeamsForCurrentUser, { teams: result })
      return true
    }
    return false
  },

  async [actionNames.fetchAndSetCurrentTeam](
    { commit },
    {
      upwId,
      programTypeId,
      divisionId,
      teamId,
    }: { upwId: string; programTypeId: string; divisionId: number; teamId: number }
  ): Promise<boolean> {
    const result = await teamClient.retrieveTeam(upwId, programTypeId, divisionId, teamId)

    if (result) {
      commit(mutationNames.setCurrentTeam, { team: result })
      return true
    }

    return false
  },

  async [actionNames.saveCurrentTeam]({ state }): Promise<void> {
    if (!state.currentTeam) throw Error('Cannot saveCurrentTeam. CurrentTeam is not set in the store')
    if (!state.currentTeam.upwardLeagueID) throw Error('Cannot saveCurrentTeam. No upwardTypeId')
    await teamClient.updateTeam(state.currentTeam.upwardLeagueID, toDivisionTeam(state.currentTeam))
  },

  async [actionNames.saveRosterChanges]({ state }): Promise<void> {
    if (!state.currentTeam) throw Error('Cannot save roster changes. CurrentTeam is not set in the store')
    if (!state.currentTeam.upwardLeagueID) throw Error('Cannot save roster changes. No upwardTypeId')
    await teamClient.saveRosterChanges(state.currentTeam.upwardLeagueID, toDivisionTeam(state.currentTeam))
  },
})

export const namespace = 'teams'

export const teams = {
  namespaced: true as true,
  state: programState,
  getters: getterTree,
  actions,
  mutations,
}
