
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { getEmptyLeaguePlayerInfoList } from '@/modelHelpers/LeaguePlayerInfo'
import { TeamPlayerInfo } from '@/GeneratedTypes/ListInfo/TeamPlayerInfo'
import Loading from '@/elements/Loading.vue'
import PlayerRankable from '@/components/PlayerRankable.vue'
import draggable from 'vuedraggable'
import { Action, Mutation, Getter } from 'vuex-class'
import * as teamStore from '@/store/team'
import * as upwardTypesStore from '@/store/upwardTypes'
import { UpwardProgramTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardProgramTypeID'
import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'
import { cloneDeep } from 'lodash'
import { rankPlayers, useRosterRanking, setRosterRanking } from '@/common/players'

@Component({
  components: {
    PlayerRankable,
    draggable,
    Loading,
  },
})
export default class Roster extends Vue {
  @Prop({ type: Array, default: getEmptyLeaguePlayerInfoList, required: false })
  private readonly players!: TeamPlayerInfo[]

  @Prop({ type: String, default: 'Players', required: false })
  private readonly title!: string

  @Prop({ type: Boolean, default: false, required: false })
  private readonly rankable!: boolean

  @Getter(teamStore.getterNames.currentTeam, { namespace: teamStore.namespace })
  private currentTeam!: DivisionTeamInfoExt

  @Getter(upwardTypesStore.getterNames.programTypes, { namespace: upwardTypesStore.namespace })
  private readonly allProgramTypes!: UpwardProgramTypeID[]

  @Mutation(teamStore.mutationNames.setCurrentTeamPlayers, { namespace: teamStore.namespace })
  private setPlayers!: ({ players }: { players: TeamPlayerInfo[] }) => void

  @Action(teamStore.actionNames.saveRosterChanges, { namespace: teamStore.namespace })
  private saveRosterChanges!: () => Promise<void>

  private localPlayers = [] as TeamPlayerInfo[]
  private drag = false
  private saving = false
  private initialRankingPerformed = false
  private dragOptions = {
    animation: 0,
    group: 'description',
    disabled: false,
    ghostClass: 'ghost',
  }

  private get isCheer(): boolean {
    const allprograms = cloneDeep(this.allProgramTypes)
    const program = allprograms.find((p) => p.upwardTypeID === this.currentTeam.typeProgramID)
    return program ? program.isCheer : false
  }

  private reorder() {
    this.drag = false
    this.localPlayers = setRosterRanking(this.localPlayers)
  }

  private async save() {
    this.saving = true
    this.setPlayers({ players: cloneDeep(this.localPlayers) })
    try {
      await this.saveRosterChanges()
    } finally {
      this.saving = false
      this.initialRankingPerformed = false
    }
  }

  @Watch('players', { immediate: true })
  private playersChanged() {
    this.localPlayers = rankPlayers(this.players)
    if (this.players.length > 0 && !useRosterRanking(this.players)) {
      this.reorder()
      this.initialRankingPerformed = true
    }
  }
}
