import restService from '@/services/restService'
import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import { DivisionTeam } from '@/GeneratedTypes/DivisionTeam'
import { DivisionTeamInfoExt } from '@/models/DivisionTeamInfoExt'

const baseUrl = 'teams/'

/**
 * Grab a list of teams for a given league and division.
 * @param leagueId
 * @param divisionId
 * @param typeProgramId
 */
const retrieveTeamList = async ({
  upwId,
  programTypeId,
}: {
  upwId: string | null
  programTypeId: string | null
}): Promise<DivisionTeamInfo[]> => {
  const result = await restService.get<DivisionTeamInfo[]>(`${baseUrl}${upwId}/${programTypeId}`)
  if (result.isSuccess) {
    return result.data ?? []
  } else {
    return Promise.reject(result.errorObject)
  }
}

const retrieveLeagueTeamList = async ({ upwId }: { upwId: string | null }): Promise<DivisionTeamInfo[]> => {
  const result = await restService.get<DivisionTeamInfo[]>(`${baseUrl}${upwId}`)
  if (result.isSuccess) {
    return result.data ?? []
  } else {
    return Promise.reject(result.errorObject)
  }
}
const retrieveCoachTeamList = async (): Promise<DivisionTeamInfo[]> => {
  const result = await restService.get<DivisionTeamInfo[]>(`${baseUrl}myCoachedTeams`)
  if (result.isSuccess) {
    return result.data ?? []
  } else {
    return Promise.reject(result.errorObject)
  }
}

const retrieveTeam = async (
  upwId: string,
  programTypeId: string,
  divisionId: number,
  teamId: number
): Promise<DivisionTeamInfoExt> => {
  const result = await restService.get<DivisionTeamInfoExt>(
    `${baseUrl}${upwId}/${programTypeId}/${divisionId}/${teamId}/sportsAppInfo`
  )
  if (result.isSuccess && result.data) {
    return result.data
  } else {
    return Promise.reject(result.errorObject)
  }
}

const retrieveByIndividualId = async (
  upwardId: string,
  individualId: string
): Promise<DivisionTeamInfo[]> => {
  const result = await restService.get<DivisionTeamInfo[]>(`${baseUrl}${upwardId}`, {
    individualID: individualId,
  })
  if (result.isSuccess && result.data) {
    return result.data
  } else {
    return Promise.reject(result.errorObject)
  }
}

const updateTeam = async (upwardLeagueId: string, team: DivisionTeam) => {
  const results = await restService.put<DivisionTeam>(
    `${baseUrl}${upwardLeagueId}/${team.typeProgramID}/${team.divisionID}/${team.teamID}`,
    team
  )
  if (!results.isSuccess) {
    throw new Error('Error in team.updateTeam')
  }
}

const rerankPlayers = async (team: DivisionTeamInfoExt): Promise<boolean> => {
  const result = await restService.post<void>(
    `${baseUrl}${team.upwardLeagueID}/${team.typeProgramID}/${team.divisionID}/${team.teamID}/rerankPlayers`,
    null
  )

  if (result.isSuccess) {
    return true
  } else {
    return false
  }
}

const setCurrentGame = async (team: DivisionTeamInfoExt, gameId: number): Promise<boolean> => {
  const result = await restService.post<void>(
    `${baseUrl}${team.upwardLeagueID}/${team.typeProgramID}/${team.divisionID}/${team.teamID}/setCurrentGame/${gameId}`,
    null
  )

  if (result.isSuccess) {
    return true
  } else {
    return false
  }
}

const saveRosterChanges = async (upwardLeagueId: string, team: DivisionTeam) => {
  const results = await restService.post<DivisionTeam>(
    `${baseUrl}${upwardLeagueId}/${team.typeProgramID}/${team.divisionID}/${team.teamID}/rosterChanges`,
    team.players
  )
  if (!results.isSuccess) {
    throw new Error('Error in team.saveRosterChanges')
  }
}

export default {
  retrieveTeamList,
  retrieveLeagueTeamList,
  retrieveCoachTeamList,
  retrieveByIndividualId,
  retrieveTeam,
  updateTeam,
  rerankPlayers,
  setCurrentGame,
  saveRosterChanges,
}
